import React from 'react';
import usaFlag from './usa-in-mortal-danger.jpg';
import './App.css';
import styled from 'styled-components';

const UsaInDangerFlag = styled.img`
  height: 40vmin;
  pointer-events: none;
`;

function App() {
  return (
    <div className="App">
      <header className="App-header">

        <UsaInDangerFlag
          src={usaFlag}
          alt="The United States of America flag displayed upside down representing it as being in mortal danger"
        />

        <h4>Trojan Trump, succeeding where Bush, Clinton, and Obama failed</h4>

        <p>
          My fellow Americans, we have been pacified...
        </p>

        <a
          className="App-link"
          href="#"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn The Truth
        </a>
        
      </header>
    </div>
  );
}

export default App;
